<template>
  <div class="room-category-item-wrapper">
    <div class="room-category-add-item--wrapper">
      <div class="room-category-add-item">
        <div class="content">
          <v-btn
            class="mx-2"
            fab
            dark
            outlined
            large
            color="primary"
            @click="$emit('showCategoryDeepOne')"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <div class="content__text">Rajouter une prestation</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryListOfRoomAddItem",
};
</script>

<style>
</style>